import {
  create,
  Dropin,
  PaymentMethodOptions,
  PaymentMethodPayload,
  cardPaymentMethodPayload,
  ChangeActiveViewPayload
} from 'braintree-web-drop-in';

/**
 * Parse a Braintree Dropin UI error message object and return it as a string.
 */
function getBraintreeError(message: string, error: any) {
  let msg = error.message;
  if (msg === undefined) {
    return message;
  }
  msg = `${message} ${msg}`;

  if (error._braintreeWebError !== undefined) {
    if (error._braintreeWebError.message !== undefined) {
      msg = `${msg} ${error._braintreeWebError.message}`;
    }
    if (error._braintreeWebError.details !== undefined) {
      if (error._braintreeWebError.details.originalError !== undefined) {
        if (error._braintreeWebError.details.originalError.error !== undefined) {
          if (error._braintreeWebError.details.originalError.error.message !== undefined) {
            msg = `${msg} ${error._braintreeWebError.details.originalError.error.message}`;
          }
        }
      }
    }
  }

  return `${msg}. Please ensure that the payment method is valid and that it has sufficient funds.`;
}

/**
 * Create a Braintree DropIn UI instance. The DropIn UI is a Braintree-hosted
 * component that allows the user to enter credit card details, or invoke the
 * PayPal UI, etc.
 *
 * The component will attempt to attach itself to a DOM element container with
 * the specified id, so make sure that such an element exists in the DOM before
 * calling this function.
 *
 * @throws DropinError if the Dropin UI cannot be created.
 * @param onViewChanged Called when the Dropin UI changes view; the parameter
 * is the id/name of the new view.
 * @param onPaymentMethodRequestable Called when the ability to request a
 * payment method via the Dropin UI changes. The parameter is true when a
 * payment method can be requested, false otherwise.
 */
export async function createDropinUI(
  clientToken: string,
  containerId: string,
  amount: number,
  googleMerchantId: string,
  onViewChanged: (view: string) => void,
  onPaymentMethodRequestable: (status: boolean) => void
): Promise<Dropin> {
  // https://braintree.github.io/braintree-web-drop-in/docs/current/module-braintree-web-drop-in.html#.create
  const ui = await create({
    // The client token (which represents a Braintree customer); obtained from the Craft backend.
    authorization: clientToken,

    // The DOM id of the element where the Dropin UI should be placed.
    container: containerId,

    /*
      Instruct the Braintree SDK to create a device data collector together with the
      Dropin UI. The data collector is required to carry out transactions for vaulted
      PayPal payment methods, but it is also required to enable Premium Fraud Protection.
      See
      https://developer.paypal.com/braintree/docs/guides/premium-fraud-management-tools/client-side/javascript/v3#drop-in
    */
    dataCollector: true,

    /*
      Instruct Braintree to ask card issuers to initiate a 3D Secure challenge.
      The purpose of a 3D Secure challenge is to transfer liability for payments
      from our Braintree Merchant account (i.e., SBL) to the card issuer.

      !!! DO NOT REMOVE THIS OPTION !!!

      It is very important that we shift liability to the card issuer when we can.

      See https://developer.paypal.com/braintree/docs/guides/3d-secure/overview
    */
    threeDSecure: true,

    /*
      Do NOT allow customers to delete stored payment methods via the Dropin UI.
      This is the recommended behaviour for recurring billing (subscriptions). 
      (We allow users to delete their payment methods via the profile page.)
    */
    vaultManager: false,

    /*
      Whether or not to initialize Drop-in with a vaulted payment method pre-selected.
      We disable this to simplify the behaviour of the signup flow components.
    */
    preselectVaultedPaymentMethod: false,

    /*
      Enable PayPal to be selectable in the Dropin UI. NOTE: In order for this to work,
      PayPal must also be enabled in the Braintree Admin panel.
    */
    paypal: {
      // The Vault flow PayPal variant is required for recurring payments, i.e. subscriptions.
      flow: 'vault',
      // All our prices are in US Dollars.
      currency: 'USD'
    },

    /*
      Additional Dropin UI parameters for cards. (Cards are always available in the Dropin UI.)
    */
    card: {
      /* Do not require users to enter their card holder name. */
      cardholderName: false,
      vault: {
        /*
          Attempt to add the card to the user's vault. We want to do this because
          it makes it easier for the user select the card again to make future purchases
          with SBL.
         */
        vaultCard: true,
        /* Do not allow the user to disable vaulting of their card. */
        allowVaultCardOverride: false
      }
    },

    // Apple Pay integration
    applePay: {
      displayName: 'Scotts Bass Lessons',
      paymentRequest: {
        countryCode: 'GB',
        currencyCode: 'USD',
        total: {
          label: 'Scotts Bass Lessons',
          amount: `${amount}`
        },
        supportedNetworks: ['visa', 'mastercard'],
        merchantCapabilities: ['supports3DS', 'supportsCredit', 'supportsDebit']
      }
    },

    // Google Pay
    googlePay: {
      googlePayVersion: 2,
      merchantId: googleMerchantId,
      transactionInfo: {
        totalPriceStatus: 'FINAL',
        totalPrice: `${amount}`,
        currencyCode: 'USD'
      }
    }
  });

  // Check if a payment method can be requested directly.
  if (ui.isPaymentMethodRequestable()) {
    onPaymentMethodRequestable(true);
  } else {
    onPaymentMethodRequestable(false);
  }

  /*
    Register Dropin UI event listeners.
  */
  ui.on('changeActiveView', (payload: ChangeActiveViewPayload) => {
    onViewChanged(payload.newViewId);
  });
  ui.on('paymentMethodRequestable', () => {
    onPaymentMethodRequestable(true);
  });
  ui.on('noPaymentMethodRequestable', () => {
    onPaymentMethodRequestable(false);
  });

  return ui;
}

export type PaymentMethodResult = {
  success: boolean;
  error?: string;
  nonce?: string;
  deviceData?: string;
};

/**
 * Request a payment method from the specified Braintree Dropin UI.
 * Return a result object with a success/failure status and a nonce
 * (that represents the payment method, on success) or an error
 * message (on fail).
 *
 * This function should be called when the user clicks a "pay now" button,
 * or similar. It communicates with Braintree's servers (possibly initiating
 * a 3DSecure challenge) to figure out if the user is allowed to make a purchase.
 * If true, it returns information about the user's payment method, which
 * includes a "nonce". A nonce is an abstraction that allows our Craft backend
 * to talk about transatctions and subscriptions with Braintree's servers without
 * including any actual details about the user's banking data. For more
 * information see, e.g.,
 *
 * https://developer.paypal.com/braintree/docs/guides/drop-in/setup-and-integration#configuring-payment-methods
 * https://developer.paypal.com/braintree/docs/guides/3d-secure/client-side/javascript/v3
 *
 * The returned result (if successful) also includes a "device data" string.
 * It is generated by Braintree's Javascript SDK, and it is used to improve
 * fraud detection. (It is also a requirement for using valuted PayPal payment
 * methods.) It must be passed to the Craft backend when purchasing an access pass.
 *
 * https://developer.paypal.com/braintree/docs/guides/premium-fraud-management-tools/client-side/javascript/v3#drop-in
 */
export async function requestPaymentMethod(
  dropin: Dropin,
  email: string,
  amount: number
): Promise<PaymentMethodResult> {
  let result: PaymentMethodPayload | undefined = undefined;

  try {
    const options: PaymentMethodOptions = {
      threeDSecure: {
        amount: `${amount}`,
        email
      }
    };
    result = await dropin.requestPaymentMethod(options);
    if (result === undefined) {
      throw new Error('Response is undefined.');
    }
  } catch (error) {
    console.error(error);
    const msg = getBraintreeError('Could not obtain a payment method.', error);
    return {
      success: false,
      error: msg
    };
  }

  switch (result.type) {
    case 'CreditCard':
      /* 
        Check if the card passed 3D Secure validation. 
        
        We tell Braintree to enable 3D Secure when we create the Dropin UI (see the 
        createDropinUI() function above). The purpose of a 3D Secure challenge is to transfer
        liability for payments from our Braintree Merchant account (i.e., SBL) to the user's
        card issuer.

        See https://developer.paypal.com/braintree/docs/guides/3d-secure/overview

        In some situations, transfer of liability is not possible at all so we have
        four possible outcomes:
        
          1) A liability shift is POSSIBLE and the shift was SUCCESSFUL:
            Carry on using the nonce.

          2) A liability shift is POSSIBLE and the shift was UNSUCCESSFUL:
            Reject the nonce. It is theoretically possible to use the nonce even though the
            3D Secure challenge failed, but doing so means that our Braintree Merchant becomes
            responsible for it. We do NOT want to assume that responsibility, so we simply
            reject the nonce. (Users in that situation will simply have to use another of our
            enabled/supported payment methods, such as PayPal.)

          3) A liability shift is IMPOSSIBLE and the shift was UNSUCCESSFUL:
            The payment method the user selected could not be validated with 3D Secure.
            The nonce can still be used, but - as in 2) above - it means that our Braintree
            Merchant becomes responsible. Hence, we reject the nonce.

          4) A liability shift is IMPOSSIBLE and the shift was SUCCESSFUL:
            This situation should never arise, and is probably a bug. Reject the nonce (obviously).

        For details, see
        https://developer.paypal.com/braintree/docs/guides/3d-secure/client-side#advanced-client-side-options
      */
      const cardResult = result as cardPaymentMethodPayload;
      if (!cardResult.liabilityShiftPossible) {
        /* 
          Always return an error if liability shift is not possible.
          
          !!! IMPORTANT !!! Do not change this behaviour! 
          
          We do NOT want to use the nonce if liability cannot be transferred to the card issuer.
        */
        return {
          success: false,
          error: '3D Secure authentication not possible.'
        };
      }

      // Liability shift IS possible.
      if (!cardResult.liabilityShifted) {
        // ...but failed, so return an error to that effect.
        return {
          success: false,
          error: '3D Secure authentication failed.'
        };
      }

      // Liability shift is possible and succeeded, so carry on.
      break;

    case 'PayPalAccount':
      // No additional checks needed here.
      // ??? Ensure that this is true!
      break;

    case 'ApplePayCard':
      // No additional checks needed here.
      break;

    case 'AndroidPayCard':
      // No additional checks needed here.
      break;

    default:
      throw new Error('Unsupported payment result type');
  }

  return {
    success: true,
    nonce: result.nonce,
    deviceData: result.deviceData
  };
}

<template>
  <div>
    <page-section :margin-top="marginTop" :margin-bottom="marginBottom">
      <page-section-header :width="'wide'" :heading="'New Courses'">
        <link-button v-if="!hideLink" :size="'lg'" :url="'/courses'" :label="'View All'" />
      </page-section-header>
    </page-section>
    <page-section :margin-top="'sm'">
      <carousel
        :width="'wide'"
        :number-of-slides="courseList.length"
        :card-size="cardSize"
        :card-aspect-ratio="'315:547'"
        :disabled="disabled"
      >
        <template v-for="(course, i) in courseList" :key="course.id" #[`${i}`]>
          <card :url="course.url">
            <template #hero>
              <card-hero-image
                v-if="course.image !== undefined"
                :config="course.image"
                :pending="course.pending"
                :is-new="course.new"
              />
              <card-hero-shimmer v-else :aspect="'aspect-[315/547]'" />
            </template>
          </card>
        </template>
      </carousel>
    </page-section>
  </div>
</template>

<script lang="ts">
import {PropType, computed, onMounted, ref} from 'vue';
import PageSection, {PageSectionMargin} from '../../../core/page/PageSection.vue';
import PageSectionHeader from '../../../core/page/PageSectionHeader.vue';
import Carousel from '../../../generic/carousel/Carousel.vue';
import Card from '../../../generic/card/Card.vue';
import CardHeroImage from '../../../generic/card/partials/hero/CardHeroImage.vue';
import CardHeroShimmer from '../../../generic/card/partials/hero/CardHeroShimmer.vue';
import LinkButton from '../../../core/button/LinkButton.vue';
import {getImageConfig} from '../../../../backend/content/content-entry/content-entry-query-utils';
import {useLoader} from '../../../vue-composition/loader/loader';
import {ContentEntry} from '../../../../backend/content/content-entry/content-entry-types';
import {CarouselCardSize, getCarouselImageWidths} from '../../../generic/carousel/carousel';
import {contentEntries} from '../../../../backend/content/content-entry/content-entry-query-builder';
import {fetchContentEntriesUpToLimit} from '../../../../backend/content/content-entry/content-entry-query-utils';
import {findImage} from '../../../../backend/image-collection/image-collection-utils';
import {ImgixImageConfig} from '../../../core/imgix-image/types';
import {entryIsPending, entryIsNew} from '../../../../backend/craft/entry/entry-utils';
import {range} from '../../../../utils/array';

export default {
  components: {
    PageSectionHeader,
    PageSection,
    Carousel,
    Card,
    CardHeroImage,
    CardHeroShimmer,
    LinkButton
  },
  props: {
    marginTop: {type: String as PropType<PageSectionMargin>, default: 'xl'},
    marginBottom: {type: String as PropType<PageSectionMargin>, default: 'none'},
    hideLink: {type: Boolean, default: false},
    cardSize: {type: String as PropType<CarouselCardSize>, default: 'xs'},
    courseIsNewMonths: {type: Number, default: undefined}
  },
  setup(props) {
    const loader = useLoader();
    const courses = ref<ReadonlyArray<ContentEntry>>([]);
    const disabled = ref(true);

    onMounted(async () => {
      loader.setLoading(true);
      courses.value = await fetchContentEntriesUpToLimit(
        10,
        contentEntries().status(['live', 'pending']).section(['courses']).orderBy('postDate DESC'),
        ce => {
          const img = findImage(ce.imageCollection, ['poster']);
          return img !== undefined;
        }
      );
      loader.setLoading(false);
      disabled.value = false;
    });

    const imageWidth = computed(() => {
      return getCarouselImageWidths(props.cardSize);
    });

    const courseList = computed(() => {
      if (courses.value.length === 0) {
        return range(10).map(i => {
          return {
            id: i,
            image: undefined,
            title: undefined,
            url: undefined,
            pending: undefined,
            new: undefined
          };
        });
      }
      return courses.value
        .map(course => {
          const poster = getImageConfig(
            course.imageCollection,
            ['poster'],
            course.title,
            imageWidth.value
          );

          let image: Readonly<ImgixImageConfig> | undefined = poster;

          /*
            Select a poster image for the course. If the entry is pending,
            look for an image with 'poster-coming-soon' handle. If it doesn't
            exist, use the regular poster image with a 'coming soon' pill.
          */
          const pending = entryIsPending(course);
          // NOTE: If the course was released within the last X months (2 months is the functional default), overlay a 'new release' banner on the image.
          const isNew = entryIsNew(course, props.courseIsNewMonths);

          // If we don't have any applicable poster image, don't display the course.
          if (image === undefined) {
            return undefined;
          }
          return {
            id: course.id,
            image,
            title: course.title,
            url: course.url,
            pending: pending,
            new: isNew
          };
        })
        .filter(c => c !== undefined);
    });

    return {
      courseList,
      disabled
    };
  }
};
</script>

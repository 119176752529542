<template>
  <div
    :class="ribbonColourGradient"
    class="absolute text-almost-black uppercase italic max-[320px]:text-[8px] text-fl-xs sm:text-fl-sm font-bold p-[0.1rem] top-[6.5%] right-[25%] w-full -rotate-[30deg] flex justify-center"
  >
    {{ statusText }}
  </div>
</template>

<script lang="ts">
import {defineComponent, computed} from 'vue';

export default defineComponent({
  props: {
    pending: {type: Boolean, default: false},
    isNew: {type: Boolean, default: false}
  },
  setup(props) {
    const statusText = computed(() => {
      if (props.pending === true) {
        return 'Coming Soon';
      } else if (props.isNew === true) {
        return 'New Release';
      }
      return undefined;
    });

    const ribbonColourGradient = computed(() => {
      if (props.pending === true) {
        return 'bg-gradient-to-r from-[#FC5244] to-[#FFDB62]';
      } else if (props.isNew === true) {
        return 'bg-gradient-to-r from-[#50FBDD] to-[#AABEFF]';
      }
      return undefined;
    });

    return {
      statusText,
      ribbonColourGradient
    };
  }
});
</script>

import {DateTime} from 'luxon';
import {Webcast} from '../../webcast/webcast-types';
import {Entry} from './entry-types';
import {CraftId} from '../craft-types';
import {
  CraftQueryCategoriesField,
  CraftQueryEntriesField,
  CraftQueryField,
  CraftQueryGlobalSet,
  CraftQueryMatrixField,
  CraftQueryMatrixFieldBlock
} from '../query/implementation/craft-query-types';

export function postDateToLocale(date: string, format: 'short' | 'long') {
  return DateTime.fromISO(date).toLocaleString(
    format === 'short' ? DateTime.DATE_SHORT : DateTime.DATETIME_FULL
  );
}

export function postDateToLocaleDateAndTime(date: string) {
  return {
    date: DateTime.fromISO(date).toLocaleString(DateTime.DATE_FULL),
    time: DateTime.fromISO(date).toLocaleString({
      hour: '2-digit',
      minute: '2-digit',
      timeZoneName: 'short',
      hour12: false
    })
  };
}

export function entryIsPending(entry: Readonly<Entry>) {
  const now = DateTime.now().valueOf();
  const postDate = DateTime.fromISO(entry.postDate).valueOf();
  return postDate > now;
}

export function entryIsNew(entry: Readonly<Entry>, monthsIsNew: number | undefined = 3) {
  const monthsBetweenDates = DateTime.now()
    .diff(DateTime.fromISO(entry.postDate), 'months')
    .toObject();
  if (monthsBetweenDates.months === undefined) {
    return;
  }
  return monthsBetweenDates.months < monthsIsNew;
}

export function entryIsPast(entry: Readonly<Entry>) {
  return !entryIsPending(entry);
}

/**
 * Returns true if the specified entry has at least one webcast that
 * is active (= in the "Webcasts" Global in Craft) AND the entry has
 * a post date in the past.
 *
 * @param webcasts The current list of active webcasts. (Can be fetched
 * via the getWebcasts() function, see /src/backend/webcast.)
 */
export function webcastIsActive(entry: Readonly<Entry>, webcasts: ReadonlyArray<Webcast>) {
  const isWebcast = webcasts.find(wc => {
    return wc.webcastEntry[0] !== undefined && wc.webcastEntry[0].id === entry.id;
  });
  return entryIsPast(entry) && isWebcast;
}

/**
 * If the entry is upcoming, return the URL for the upcoming
 * entries page (with the entry slug as parameter). If not,
 * return the entry URL as-is.
 *
 * (We need this mechanism because Craft redirects to the 404
 * page if you navigate to an entry with a post date in the
 * future.)
 */
export function entryUrlWithStatus(entry: Readonly<Entry>) {
  if (entryIsPending(entry)) {
    return `/upcoming-entry?slug=${entry.slug}`;
  }
  return entry.url;
}

function removeDuplicatesFromMatrixBlock(
  block: Readonly<CraftQueryMatrixFieldBlock>,
  blockInstance: Readonly<any>
) {
  const fields = block.fields;
  const result: any = {...blockInstance};
  fields.forEach(field => {
    const handle = field.handle;
    if (handle === undefined) {
      throw new Error('Matrix block field has no handle');
    }
    const fieldData = blockInstance[handle];
    result[handle] = removeDuplicates(field, fieldData);
  });
  return result;
}

function removeDuplicatesFromMatrixField(
  field: Readonly<CraftQueryMatrixField>,
  blockInstances: ReadonlyArray<any>
) {
  const result = blockInstances.map(blockInstance => {
    const blockInstanceHandle = blockInstance['typeHandle'];
    if (blockInstanceHandle === undefined) {
      throw new Error('Matrix block data has no type handle');
    }
    const block = field.blocks.find(b => b.handle === blockInstanceHandle);
    if (block === undefined) {
      throw new Error(`Matrix field definition has no block named ${blockInstanceHandle}`);
    }
    return removeDuplicatesFromMatrixBlock(block, blockInstance);
  });
  return result;
}

function idFilter(elements: ReadonlyArray<any>) {
  const ids = new Set<CraftId>();
  return elements.filter(e => {
    const id = e['id'];
    if (id === undefined) {
      throw new Error('No id');
    }
    if (ids.has(id)) {
      return false;
    }
    ids.add(id);
    return true;
  });
}

function removeDuplicatesFromElementsField(
  field: Readonly<CraftQueryEntriesField | CraftQueryCategoriesField | CraftQueryGlobalSet>,
  data: ReadonlyArray<any>
) {
  const elements = data as Array<any>;
  const fields = field.fields;
  if (fields === undefined || fields.length === 0) {
    return idFilter(elements);
  }
  return idFilter(
    elements.map(e => {
      const result: any = {...e};
      if (fields === undefined || fields.length === 0) {
        return result;
      }
      fields.forEach(subfield => {
        const handle = subfield.handle;
        if (handle === undefined) {
          throw new Error('Field has no handle');
        }
        const fieldData = e[handle];
        result[handle] = removeDuplicates(subfield, fieldData);
      });
      return result;
    })
  );
}

/**
 * Given an entry field and an array of entries assumed to be in that
 * field, remove any duplicated entries (with respect to entry id).
 * The removal is recursive; i.e., if the field has sub-fields of type
 * 'entries' then the filter is applied to those to.
 */
export function removeDuplicates(
  field: Readonly<CraftQueryField>,
  data: ReadonlyArray<any>
): ReadonlyArray<any> {
  switch (field.type) {
    case 'matrix':
      return removeDuplicatesFromMatrixField(field, data);
    case 'entries':
    case 'categories':
    case 'globalSet':
      return removeDuplicatesFromElementsField(field, data);
    default:
      return data;
  }
}

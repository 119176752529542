<template>
  <a
    v-if="size === 'lg'"
    :id="'players-path-quiz-button'"
    :href="`${entryQuiz.url}?levelSlug=${levelSlug}`"
    :class="`max-w-[470px] py-fl-md px-fl-md-2xl border-2 rounded-md ${cfg} flex justify-center gap-fl-sm flex-nowrap items-center cursor-pointer select-none text-fl-lg font-semibold`"
  >
    <font-awesome-icon v-if="icon" :icon="icon" aria-hidden="true"></font-awesome-icon>
    <div :class="`text-left`">{{ title }}</div>
  </a>
  <a
    v-else
    :href="`${entryQuiz.url}?levelSlug=${levelSlug}`"
    :class="`max-w-[470px] py-fl-xs w-full border-2 rounded-md ${cfg} flex justify-center gap-fl-sm flex-nowrap items-center cursor-pointer select-none text-fl-lg font-semibold`"
  >
    <font-awesome-icon v-if="icon" :icon="icon" aria-hidden="true"></font-awesome-icon>
    <div :class="`text-left`">{{ title }}</div>
  </a>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from 'vue';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {ColorConfig} from '../../../core/color-config';
import {Quiz} from '../../../../backend/content/quiz/quiz-types';
import {faList} from '@fortawesome/pro-light-svg-icons/faList';

/**
 * A styled, basic button with a label.
 */
export default defineComponent({
  components: {
    FontAwesomeIcon
  },
  props: {
    spotColor: {type: Object as PropType<Readonly<ColorConfig>>, required: true},
    entryQuiz: {type: Object as PropType<Readonly<Quiz>>, required: true},
    size: {type: String as PropType<'sm' | 'lg'>, default: 'lg'},
    title: {type: String, default: 'Take Entry Quiz'},
    levelSlug: {type: String, default: undefined}
  },
  setup(props) {
    const icon = computed(() => faList);
    const cfg = computed(
      () =>
        `${props.spotColor.text} ${props.spotColor.border} ${props.spotColor.textHover} ${props.spotColor.borderHover}`
    );
    return {
      icon,
      cfg
    };
  }
});
</script>

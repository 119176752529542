<template>
  <div class="relative w-full rounded-[0.5rem] overflow-hidden">
    <div v-if="config !== undefined">
      <imgix-image v-if="greyscale" class="w-full grayscale" :config="config" />
      <imgix-image v-else class="w-full" :config="config" />
    </div>
    <card-hero-inset-text :inset-text="insetText" />
    <card-hero-ribbon :ribbon-icon="ribbonIcon" />
    <course-status-ribbon v-if="showCourseRibbon" :pending="pending" :is-new="isNew" />
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType, computed} from 'vue';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';
import ImgixImage from '../../../../core/imgix-image/ImgixImage.vue';
import CardHeroInsetText from './partials/CardHeroInsetText.vue';
import CardHeroRibbon from './partials/CardHeroRibbon.vue';
import {ImgixImageConfig} from '../../../../core/imgix-image/types';
import CourseStatusRibbon from './partials/CourseStatusRibbon.vue';

export default defineComponent({
  components: {
    ImgixImage,
    CardHeroInsetText,
    CardHeroRibbon,
    CourseStatusRibbon
  },
  props: {
    config: {type: Object as PropType<Readonly<ImgixImageConfig>>, default: undefined},
    greyscale: {type: Boolean, default: false},
    insetText: {type: String, default: undefined},
    ribbonIcon: {type: Object as PropType<IconDefinition>, default: undefined},
    pending: {type: Boolean, default: false},
    isNew: {type: Boolean, default: false}
  },
  setup(props) {
    const showCourseRibbon = computed(() => {
      if (props.pending === true || props.isNew === true) {
        return true;
      }
      return false;
    });

    return {
      showCourseRibbon
    };
  }
});
</script>
